.adminPage
  width: 80%
  margin: 2rem auto
  text-align: center
  font-family: 'MontserratReg', sans-serif
  font-size: 14px
  min-height: 100vh

  &_title
    color: white
    font-family: sans-serif
    font-weight: 600
    font-size: 2rem

  &_container
    display: flex
    align-items: center
    flex-wrap: wrap
    gap: 30px

  &_header
    display: flex
    align-items: center
    justify-content: space-between

  &_button
    border: none
    text-align: center
    padding: 0.5rem 0.9rem
    border-radius: 5px
    color: white
    background-color: $blue
    width: 8rem

.mb20
  margin-bottom: 20px

.adminPageCard
  background: white
  border-radius: 8px
  padding: 5px 10px 15px
  margin-bottom: 2rem
  width: 280px
  height: 640px
  overflow-y: auto
  -ms-overflow-style: none
  scrollbar-width: none

  &::-webkit-scrollbar
    width: 0
    height: 0


  &_title
    font-size: 16px
    color: $purple
    font-weight: 600

  & > ul
    margin: 0
    padding: 0
    list-style: none
    text-align: left

  &_item
    &--link
      display: block
      padding-bottom: 15px
      text-decoration: none
      color: black

      & > strong
        font-size: 14px
        padding-right: 10px
        font-family: sans-serif

    & > p > strong
      display: flex
      align-items: baseline
      justify-content: flex-start
      flex-wrap: wrap
      font-size: 14px
      padding-right: 10px
      font-family: sans-serif

@media(max-width: 700px)
  .adminPageCard
    width: 48%

@media(max-width: 630px)
  .adminPage
    &_container
      justify-content: center
  .adminPageCard
    width: 70%

    &_title
      font-size: 2rem

@media(max-width: 480px)
  .adminPage
    &_container
      justify-content: center
  .adminPageCard
    width: 100%

    &_title
      font-size: 1.8rem


