.adminPage

.auth
  height: 90vh
  width: 100%
  position: relative
  &_wrapper
    background: white
    width: 30%
    height: 22rem
    border-radius: 10px
    position: absolute
    top: 42%
    left: 50%
    transform: translate(-50%, -50%)
    text-align: center
    padding: 0.5rem 1.5rem
    box-shadow: 0 0 10px 8px rgba(black, 0.3)
  &_header
    font-family: 'MontserratBold', sans-serif
    font-size: 2rem
    color: $purpleDark
  &_form
    display: flex
    align-items: center
    justify-content: center
    flex-direction: column
  &_button
    color: white
    font-family: 'MontserratMedium', sans-serif
    font-size: 1.5rem
    background-color: $purpleDark
    border-radius: 5px
    border: none
    padding: 0.6rem 1.2rem
    margin-top: 1.5rem
    cursor: pointer

@media (max-width: 1360px)
  .auth
    &_wrapper
      width: 45%

@media (max-width: 960px)
  .auth
    &_wrapper
      height: 24rem
    &_button
      font-size: 1.6rem
      padding: 1rem 2rem

@media (max-width: 580px)
  .auth
    &_wrapper
      height: 26rem
      width: 65%

@media (max-width: 420px)
  .auth
    &_wrapper
      width: 90%
      padding: 0.5rem 0.5rem
    &_button
      font-size: 1.8rem