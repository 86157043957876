.security
  background: $purpleLight
  margin-top: 4rem
  &_wrapper
    padding: 2.813rem 0 3.313rem
    text-align: center
    display: flex
    align-items: center
    flex-direction: column

  &_header
    margin: 0
    font-family: "MontserratBold", sans-serif
    font-size: 2.375rem
    color: white
    width: 80%
    text-transform: uppercase
    position: relative

    &:before
      content: ""
      position: absolute
      background: $orange
      height: 0.375rem
      width: 7.688rem
      bottom: -30%
      left: 40%

  &_list
    width: 100%
    display: flex
    align-items: center
    justify-content: space-evenly
    flex-wrap: wrap
    padding-top: 4.688rem
    gap: 80px 20px
    margin-bottom: 60px

    &--item
      width: 30%
      position: relative
      z-index: 3

      &:after
        content: ""
        position: absolute
        z-index: -1
        background: $blue
        height: 4.813rem
        width: 4.813rem
        border-radius: 50%
        top: 10%
        right: 30%

      &-text
        margin: 0
        padding-top: 1.625rem
        font-family: "MontserratReg", sans-serif
        font-weight: 500
        font-size: 1.125rem
        color: white
        width: 99%

      &-subtext
        max-width: 500px
        margin: 0
        padding-top: 1.625rem
        font-family: "MontserratReg", sans-serif
        font-weight: 500
        font-size: 0.925rem
        color: rgba(white, 0.8)
        width: 99%

      &-img
        height: 6.625rem


@media (max-width: 460px)
  .security
    &_header
      font-size: 1.8rem
      &:before
        left: 30%
    &_list
      flex-wrap: wrap
      &--item
        width: 45%
        padding-bottom: 3rem
