@font-face
  font-family: 'MontserratReg'
  src: url('../assets/fonts/MontserratRegular.ttf') format('truetype')
  font-weight: normal
  font-style: normal

@font-face
  font-family: 'MontserratMedium'
  src: url('../assets/fonts/MontserratMedium.ttf') format('truetype')
  font-weight: normal
  font-style: normal

@font-face
  font-family: 'MontserratBold'
  src: url('../assets/fonts/MontserratBold.ttf') format('truetype')
  font-weight: normal
  font-style: normal

@font-face
  font-family: 'MontserratBlack'
  src: url('../assets/fonts/MontserratBlack.ttf') format('truetype')
  font-weight: normal
  font-style: normal