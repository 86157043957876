@import "../../styles/constants"

.modalWrapper
  position: fixed
  top: 0
  left: 0
  z-index: 200
  width: 100%
  height: 100%
  display: flex
  align-items: center
  justify-content: center
  background-color: rgba($purple, 0.5)
  backdrop-filter: blur(4px)

.modal
  position: relative
  width: 840px
  height: 670px
  background-color: white
  border-radius: 8px
  overflow: hidden
  text-align: center
  animation-name: modalOpen
  animation-duration: 0.3s

.head
  flex-shrink: 0
  position: relative
  width: 100%
  padding: 4px

.headGreen
  background-color: $green

.headBlue
  background-color: $blue

.headOrange
  background-color: $orange

.header
  margin: 0
  font-family: 'MontserratBold', sans-serif
  font-size: 25px
  line-height: 30px
  text-transform: capitalize
  color: white

.content
  display: flex
  align-items: center
  justify-content: space-between
  flex-direction: column
  padding: 1rem 2rem 0.5rem

.text
  margin: 0
  font-family: 'MontserratReg', sans-serif
  font-size: 14px
  line-height: 18px
  text-align: left

.textStrong
  font-family: 'MontserratBold', sans-serif
  font-size: 14px
  line-height: 18px

.df
  width: 100%
  display: flex
  align-items: center
  justify-content: space-between

.dc
  justify-content: space-evenly

.bannerWrapper
  width: 45px
  display: flex
  align-items: center
  justify-content: center
  margin-right: 9px

.banner
  display: flex
  align-items: center
  justify-content: center
  height: 45px
  padding: 12px 20px
  transform: rotate(-90deg)
  background-color: $orange
  border-radius: 132px

.bannerText
  font-family: 'MontserratBold', sans-serif
  font-size: 16.2236px
  line-height: 20px
  color: $purple

.bannerSm
  display: flex
  align-items: center
  justify-content: center
  height: 33px
  padding: 12px 14px
  transform: rotate(-4.5deg)
  background-color: $orange
  border-radius: 132px
  margin: 5px 0 10px

.bannerTextSm
  font-family: 'MontserratBold', sans-serif
  font-size: 14px
  line-height: 18px
  color: $purple

.infoWrapper
  position: relative
  width: 100%
  display: flex
  align-items: center
  justify-content: center
  flex-direction: column

.infoImgF
  position: absolute
  bottom: 0
  right: -10px
  width: 49px
  transform: rotate(15deg) translateY(50%)

.infoImgS
  position: absolute
  bottom: 15px
  right: 0
  width: 50px
  transform: rotate(-3deg) translateX(50%)

.infoImgT
  position: absolute
  top: 0
  right: 25px
  width: 40px

.infoHeader
  margin: 0
  padding: 1.3px 18px
  background-color: $orange
  color: white
  font-family: 'MontserratBold', sans-serif
  font-size: 15px
  line-height: 18px
  border-top-left-radius: 8px
  border-top-right-radius: 8px

.info
  width: 320px
  height: 110px
  padding: 0 9px
  border: 1px solid $orange
  border-radius: 8px

.infoLong
  width: 360px
  height: 140px
  margin-left: 15px

.infoSm
  width: 360px
  height: 140px

.firstText
  width: 300px
  flex-shrink: 0

.infoList
  display: flex
  justify-content: center
  flex-direction: column
  list-style-type: none
  height: 100%
  width: 100%
  margin: 0
  padding: 0

.infoItem
  display: flex
  align-items: flex-start
  margin-bottom: 6px

  &:last-child
    margin-bottom: 0

.infoMarker
  width: 21px
  margin-right: 6px

.mb6
  margin-bottom: 6px

.mb13
  margin-bottom: 13px

.mb20
  margin-bottom: 20px

.mt20
  margin-top: 20px

.img
  position: absolute
  right: 20px
  bottom: 75px
  height: 55px
  transform: rotate(10deg)

.subtext
  font-family: 'MontserratReg', sans-serif
  font-size: 12px
  line-height: 15px

.close
  position: absolute
  top: -2px
  right: 0
  align-items: center
  justify-content: center
  width: 40px
  height: 40px
  background: none
  border: none
  color: white
  display: none
  overflow: hidden

  & > p
    font-size: 40px
    transform: rotate(-45deg)


@keyframes modalOpen
  from
    transform: scale(0.5)
  to
    transform: scale(1)

@media (max-width: 1360px)
  .modal
    height: 645px

@media (max-width: 860px)
  .modalWrapper
    height: 101vh
    overflow-y: scroll
    -ms-overflow-style: none
    scrollbar-width: none
    background: white
    padding-bottom: 20px

    & ::-webkit-scrollbar
      width: 0
      height: 0

  .modal
    display: flex
    flex-direction: column
    width: 100%
    height: 100%
    border-radius: 0
    overflow-y: scroll
    padding-bottom: 15px


  .content
    height: auto
    padding-top: 20px
    padding-bottom: 40px

  .df
    flex-direction: column

  .banner
    transform: none

  .info
    width: 320px
    height: 110px
    margin-bottom: 20px

  .infoLong
    width: 320px
    height: 160px

  .infoSm
    width: 320px
    height: 160px

  .infoImgF
    position: absolute
    bottom: 23px
    right: -10px
    width: 45px

  .img
    display: none

  .close
    display: flex
