.container
  width: 1300px
  margin: auto

.title
  width: 100%
  height: 6.125rem
  background: $orange
  color: $purple
  font-family: "MontserratMedium", sans-serif
  font-weight: 700
  font-size:  1.5rem

@media (max-width: 1800px)
  \:root
    font-size: 16px

@media (max-width: 1360px)
  \:root
    font-size: 14px
  .container
    width: 96%

@media (max-width: 1060px)
  \:root
    font-size: 10px