.capabilities
  padding: 2rem 0
  font-family: 'MontserratReg', sans-serif

  &-title
    display: flex
    align-items: center
    justify-content: center
    font-size: 2.15rem
    position: relative
    overflow: visible
    text-align: center

    &:before
      content: ""
      position: absolute
      background: url("../../assets/img/smile_blue.svg") center/contain no-repeat
      z-index: 2
      width: 5rem
      height: 5rem
      left: 20%
      bottom: -4rem

  &--header
    position: relative
    margin-top: 4rem
    margin-bottom: 4rem
    font-family: 'MontserratBold', sans-serif
    font-weight: 900
    line-height: 3rem
    color: white
    text-align: center
    text-transform: uppercase

    &:after
      content: ""
      position: absolute
      left: 50%
      bottom: 0
      height: 6px
      width: 19rem
      transform: translateX(-50%)
      background-color: $yellow

  &--subtext
    font-family: 'MontserratReg', sans-serif
    font-size: 1.079rem
    margin: 5px 0


  &_content
    background-color: white
    border-radius: 30px
    padding: 3rem 4.785rem 3.125rem
    position: relative

    &:before
      content: ""
      position: absolute
      background: url("../../assets/img/blotch_green.png") center/contain no-repeat
      z-index: -1
      width: 25rem
      height: 25rem
      bottom: -13rem
      left: -15rem

  &--title
    margin: 0
    font-family: 'MontserratBold', sans-serif
    font-size: 1.5rem
    line-height: 1.8125rem
    color: $purple

    &-center
      text-align: center

    &-mr
      margin-top: 2.375rem
      margin-bottom: 1rem

  &--subtitle
    margin-top: 0.375rem
    text-align: center
    font-family: 'MontserratReg', sans-serif
    font-size: 1rem
    line-height: 1.25rem

  &_grid
    display: grid
    grid-template-columns: 1fr 1fr
    grid-column-gap: 2.125rem
    grid-row-gap: 4.3rem

  &_card
    display: flex
    width: 100%
    height: 8.56rem
    border-radius: 18px
    overflow: hidden
    color: white

    &--header
      margin: 0 0 0.3rem
      font-family: 'MontserratBlack', sans-serif
      font-size: 1.5rem
      text-transform: uppercase

    &--text
      margin: 0
      font-family: 'MontserratReg', sans-serif
      font-size: 1.5rem

    &--blue
      background-color: $blue-sec

    &--pur
      background-color: $purple

    &--content
      padding: 2rem 1.625rem

  &_cardLeft
    display: flex
    align-items: center
    justify-content: center
    flex-direction: column
    flex-shrink: 0
    width: 6.135rem
    background-color: $yellow

    &--content
      transform: rotate(-90deg)
      margin: 0
      text-align: center

    &--text
      font-family: 'MontserratBlack', sans-serif
      font-size: 2.8125rem

      &-white
        color: white

      &-pur
        color: $purple

    &--subtext
      font-family: 'MontserratBlack', sans-serif
      font-size: 1.875rem
      text-transform: uppercase

      &-white
        color: white

      &-blue
        color: $blue-sec

  &_area
    display: flex
    align-items: center
    justify-content: center
    flex-direction: column

    &--text
      font-family: 'MontserratBlack', sans-serif
      font-size: 1.5rem
      color: $purple

    &--transform
      transform: rotate(-2deg)

  &_oval
    display: inline-flex
    align-items: center
    justify-content: center
    height: 3.625rem
    min-width: 16.4rem
    padding: 0 2rem
    border-radius: 100px
    overflow: hidden

    &--pur
      transform: rotate(-5deg)
      background-color: $purple

    &--yell
      background-color: $yellow

    &--text
      font-family: 'MontserratBold', sans-serif
      font-size: 2rem
      text-transform: uppercase
      color: white

  &_list
    width: 100%
    list-style-type: none

    &--item
      position: relative
      font-family: 'MontserratReg', sans-serif
      font-size: 1.5rem
      margin-bottom: 1.375rem
      color: $purple

      &-text
        text-align: center
        margin-bottom: 55px

        &:before
          content: none !important

      &:before
        position: absolute
        left: -1.725rem
        content: ""
        width: 1.225rem
        height: 2rem
        background-image: url("../../assets/img/icon_jet.svg")
        background-size: cover

    &--wrapper
      display: flex
      align-items: center
      flex-direction: column
      margin-top: 4rem

    &--header
      display: flex
      align-items: center
      justify-content: center
      width: 15rem
      height: 3.125rem
      font-family: 'MontserratBold', sans-serif
      font-size: 1.5rem
      border-radius: 8px
      background-color: $purple
      color: white

  &_banner
    width: 100%
    display: flex
    justify-content: center
    margin-top: 1rem
    margin-bottom: 5.5rem

    &--block
      display: flex
      align-items: center
      justify-content: center
      position: relative
      width: 28.5rem
      height: 7.5rem
      border-radius: 110px
      background-color: $green
      transform: rotate(-10deg) translateX(15%)

    &--text
      font-family: 'MontserratBold', sans-serif
      font-size: 2rem
      text-align: center
      line-height: 135%
      color: white

    &--img
      position: absolute
      top: 0
      left: 0
      width: 6rem
      transform: rotate(-5deg) translateY(-50%) translateX(-35%)

  &_partners
    &--header
      font-family: 'MontserratReg', sans-serif
      font-size: 1.5rem
      text-align: center

    &--list
      display: flex
      align-items: center
      justify-content: space-between
      padding: 0
      margin-bottom: 3.5rem
      list-style-type: none

    &--item
      &-text
        font-family: 'MontserratReg', sans-serif
        font-size: 1.5rem
        color: rgba($black, 0.5)


@media (max-width: 960px)
  .capabilities
    &_partners
      &--img
        width: 5.5rem

@media (max-width: 610px)
  .capabilities
    &--header
      &:after
        width: 22rem

    &_content
      padding: 3rem 2rem

    &_grid
      grid-template-columns: 1fr
      grid-row-gap: 3rem

    &_partners
      &--list
        flex-wrap: wrap
        justify-content: space-evenly
        gap: 20px

    &_banner
      &--block
        width: 23.5rem

      &--text
        font-size: 1.7rem