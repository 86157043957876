.menu
  position: fixed
  width: 100vw
  top: 5.375rem
  left: 0
  background: rgba($purpleDark, 0.95)
  &_wrapper
    list-style: none
    padding: 3rem 0 3rem 0
    text-align: right
  &_item
    padding: 1rem
    display: flex
    align-items: flex-start
    justify-content: center
    flex-direction: column
    cursor: pointer
    &--button
      width: 100%
      padding: 0.8rem
      display: flex
      align-items: center
      justify-content: center
    &>a
      color: white
      font-size: 3rem
      font-family: 'MontserratReg', sans-serif
      &:hover
        color: $green

@media (max-width: 560px)
  .menu
    height: 95vh