.course
  position: relative
  font-family: 'MontserratReg', sans-serif

  &--header
    position: relative
    flex-shrink: 0
    margin-top: 4rem
    margin-bottom: 4rem
    font-family: 'MontserratBold', sans-serif
    font-weight: 900
    line-height: 3rem
    color: white
    text-align: center
    text-transform: uppercase

    &:after
      content: ""
      position: absolute
      left: 50%
      bottom: 0
      height: 6px
      width: 20rem
      transform: translateX(-50%)
      background-color: $yellow

  &-wrapper
    display: flex
    justify-content: space-between
    flex-wrap: wrap
    gap: 1.5rem

  &_card
    position: relative
    display: flex
    align-items: center
    justify-content: center
    flex-direction: column
    width: 24.875rem
    border-radius: 8px

    &--green
      height: 34rem
      color: $green
      background-color: $green


    &--blue
      height: 41rem
      color: $blue
      background-color: $blue

    &--orange
      height: 45rem
      color: $orange
      background-color: $orange

    &-head
      position: relative
      height: 7.375rem
      display: flex
      align-items: center
      justify-content: center
      padding-top: 24px

      &:before
        content: ''
        position: absolute
        top: -1.5rem
        left: calc(50% - 1.5rem)
        width: 3rem
        height: 3rem
        border-radius: 50%
        background-color: $purple

      &--text
        font-family: 'MontserratBold', sans-serif
        font-size: 2rem
        text-transform: capitalize
        color: white

    &-content
      width: 100%
      display: flex
      align-items: center
      justify-content: space-between
      flex-direction: column
      flex: 1
      padding: 1.5rem 2rem 3.125rem
      background-color: white
      border-bottom-left-radius: 8px
      border-bottom-right-radius: 8px

    &--text
      margin: 0
      font-family: 'MontserratBold', sans-serif
      font-size: 1.125rem
      line-height: 120%
      color: $purple

    &-textList
      padding-left: 20px
      margin-top: 1.75rem
      list-style-type: none

      &--item
        position: relative
        font-family: 'MontserratBold', sans-serif
        font-size: 1.125rem
        color: $purple
        margin-bottom: 0.4rem

        &:before
          content: ''
          position: absolute
          bottom: 0.2rem
          left: -1.5rem
          width: 1rem
          height: 1rem
          border-radius: 50%
          background-color: $orange

    &-banner
      display: flex
      align-items: center
      justify-content: center
      margin-top: 1.5rem
      border-radius: 70%
      transform: rotate(-3deg)

      &--wrapper
        display: flex
        justify-content: center

      &--orange
        width: 15.625rem
        height: 4.25rem
        background-color: $orange

      &--blue
        width: 13.5rem
        height: 3.625rem
        margin-top: 1rem
        background-color: $blue

      &--text
        font-family: 'MontserratBold', sans-serif
        font-size: 1.375rem
        color: white

      &--img
        position: absolute
        top: -30%
        right: 2%
        width: 3.125rem

        &--orange
          top: -38%
          transform: rotate(35deg)

    &-point
      margin-top: 0.5rem
      list-style-type: none
      padding-left: 30px

      &--img
        position: absolute
        width: 2rem
        left: -2.15rem
        bottom: 30%

      &--item
        position: relative
        font-family: 'MontserratBold', sans-serif
        font-size: 1.125rem
        line-height: 135%
        margin-bottom: 0.45rem

    &-img
      position: absolute
      right: 12%
      top: 51%
      width: 5rem
      transform: rotate(15deg)

    &-btn
      position: absolute
      bottom: 0
      transform: translateY(50%)

      &--info
        width: 20rem
        height: 3.4375rem
        border-radius: 8px
        background-color: $purple
        border: none
        font-family: 'MontserratReg', sans-serif
        font-size: 1.2rem
        color: white
        transition: 0.3s
        cursor: pointer

        &:hover, &:focus
          background-color: $purpleLight

        &:active
          transform: scale(0.95)

    &-price
      color: $purple
      text-align: center
      margin-top: 1rem

      &--text
        position: relative
        font-family: 'MontserratBold', sans-serif
        font-size: 3rem

      &--subtext
        position: absolute
        top: 20%
        font-family: 'MontserratReg', sans-serif
        font-size: 1rem

@media (max-width: 80rem)
  .course
    &-wrapper
      gap: 4rem
      justify-content: space-evenly

    &_card
      &--blue
        height: 43rem

@media (max-width: 560px)
  .course
    &-wrapper
      gap: 80px

    &--header
      &:after
        width: 22rem

    &_card
      width: 90%

      &--green
        height: 55rem

      &--blue
        height: 62rem

      &--orange
        height: 65rem

      &-head
        height: 118px

        &:before
          top: -25px
          left: calc(50% - 25px)
          width: 50px
          height: 50px

        &--text
          font-size: 26px

      &--text
        font-size: 16px

      &-textList
        &--item
          font-size: 16px

          &:before
            left: -20px
            width: 16px
            height: 16px

      &-banner
        &--orange
          width: 250px
          height: 66px

        &--blue
          width: 216px
          height: 57px
          margin-top: 16px

        &--text
          font-size: 20px

        &--img
          width: 43px

          &--orange
            top: -38%
            transform: rotate(35deg)

      &-point
        margin-top: 10px

        &--img
          width: 32px
          left: -38px

        &--item
          font-size: 16px
          margin-bottom: 6px

      &-img
        width: 80px

      &-btn
        &--info
          width: 250px
          height: 55px
          font-size: 18px

      &-price
        margin-top: 16px

        &--text
          font-size: 50px

        &--subtext
          font-size: 16px