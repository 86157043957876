.study
  padding-top: 4rem
  font-family: "MontserratReg", sans-serif

  &_wrapper
    display: flex
    align-items: center
    justify-content: space-between
    flex-direction: column
    text-align: center
    padding-top: 3rem
    position: relative

  &_btn
    &-tg
      flex-shrink: 0
      display: flex
      align-items: center
      justify-content: center
      background-color: $blue
      padding: 0.7rem
      border-radius: 8px
      width: 4rem
      height: 4rem
      margin-left: 20px
      transition: 0.3s

      &:hover, &:focus
        background-color: darken($blue, 5%)

      &:active
        transform: scale(0.95)

      & > img
        width: 100%
        height: 100%

  &_title
    height: auto
    padding: 15px
    display: flex
    text-align: left
    align-items: center
    justify-content: center
    font-size: 2.15rem
    position: relative
    overflow: visible

    &:before
      content: ""
      position: absolute
      background: url("../../assets/img/icon_jet.svg") center/contain no-repeat
      z-index: 2
      width: 4rem
      height: 4rem
      right: 20%
      top: -30%

  &_subtitle
    font-family: "MontserratBold", sans-serif
    font-size: 1.35rem
    padding: 1rem 2.5rem
    margin-bottom: 3.5rem
    border-radius: 15px
    border: 4px solid white
    transform: rotate(-2deg)
    color: $purple
    background-color: $orange
    position: relative

    &:before
      content: ""
      position: absolute
      background: url("../../assets/img/smile_blue.svg") center/contain no-repeat
      z-index: 2
      width: 5rem
      height: 5rem
      left: -3rem

  &_content
    padding: 1rem 0
    display: flex
    align-items: center
    justify-content: space-between
    width: 100%
    position: relative
    z-index: 3
    font-weight: 900

    &--slider
      width: 60%

    &--text
      width: 36%
      height: 33rem
      background-color: white
      padding: 0 2rem
      border-radius: 5px

.textStudy
  &_header
    color: $purple
    font-family: "MontserratBold", sans-serif
    font-weight: bold
    font-size: 1.5rem
    line-height: 126%
    margin: 2rem 0

  &_content
    text-align: left
    font-size: 1.3rem
    line-height: 126%

.slider
  &_item
    width: 100%
    height: 33rem

    &--img
      margin: auto
      width: 100%
      height: 100%
      border-radius: 5px
      overflow: hidden
      object-fit: cover

.slick
  position: relative

  &-prev, &-next
    color: white

  &-prev
    margin-left: 3.5rem
    z-index: 5

  &-next
    margin-right: 3.5rem
    z-index: 5


@media (max-width: 1340px)
  .slick
    &-prev, &-next
      width: 45px
      height: 45px
      object-fit: cover
  .study
    &_title
      &:before
        right: 5%

@media (max-width: 1200px)
  .study
    &_content
      &--text
        width: 37.95%

@media (max-width: 720px)
  .study
    &_content
      align-items: center
      flex-direction: column

      &--slider
        width: 80%
        margin-bottom: 2.5rem

      &--text
        text-align: center
        width: 80%
        display: flex
        align-items: center
        justify-content: center
        flex-direction: column

@media (max-width: 560px)
  .slider
    &_item
      height: 30rem
  .study
    &_content
      &--slider
        width: 90%

      &--text
        width: 90%

    &_title
      &:before
        right: 0
        bottom: -6.5rem
        z-index: 5

    &_subtitle
      width: 90%

    &_btn
      &-tg
        width: 5rem
        height: 5rem


@media (max-width: 470px)
  .study
    &_content
      &--text
        padding: 1.5rem
        height: 35rem

    &_title
      &:before
        right: 2%
  .slider
    &_item
      height: 20rem
  .textCups
    &_btn
      bottom: 12%