.header
  background-color: white
  background-image: url('../../assets/img/blotch_purple.png')
  background-repeat: no-repeat
  background-position: right
  background-size: 55% 65%
  padding-top: 8rem
  padding-bottom: 2rem

  &_subtitle
    padding-top: 4rem
    font-family: 'MontserratMedium', sans-serif
    font-size: 1.5rem
    color: $purple
    text-align: center

  &_wrapper
    display: flex
    align-items: center
    justify-content: space-between

  &_text
    width: 43%

    &--subtitle
      height: 14rem
      font-family: 'MontserratBold', sans-serif
      font-size: 2.375rem
      text-transform: uppercase
      color: $purple
      font-weight: bolder

      &-green
        font-size: 2.5rem
        font-weight: 900
        color: $green

    &--logo
      height: 4.7rem

  &_info
    width: 45%
    position: relative
    overflow: visible
    z-index: 0

    &--pab
      display: flex
      align-items: center
      justify-content: center

    &--location
      display: flex
      align-items: flex-end
      color: white
      font-size: 1.5rem
      font-family: 'MontserratMedium', sans-serif
      padding-left: 2rem

      & > img
        margin-right: 0.5rem

    &--course
      display: flex
      flex-direction: column
      align-items: flex-start
      justify-content: space-between

      & > img
        height: 3rem
        margin-top: 1rem

    &--img
      display: flex
      align-items: flex-end
      justify-content: flex-end

      & > img
        width: 6rem

    &--button
      width: 100%
      display: none

@media (max-width: 1500px)
  .header
    &_info
      &--pab
        align-items: flex-end
        justify-content: center

@media (max-width: 560px)
  .header
    display: flex
    flex-direction: column
    min-height: 90vh
    height: 100%
    background-image: url('../../assets/img/blotch_purple(little).png')
    background-size: 100% 40%
    padding: 0.5rem 0 1.5rem

    &_container
      display: flex
      flex: 1

    &_wrapper
      flex-direction: column
      padding: 2rem

    &_text
      width: 100%

      &--logo
        display: none

      &--subtitle
        height: auto

      &--button
        display: none

    &_info
      width: 100%
      align-items: center

      &--pab
        display: none

      &--button
        display: flex
        align-items: center
        justify-content: center
        padding-bottom: 2rem
