@import "/src/styles/constants"

.timer

  &_wrapper
    display: flex
    align-items: center
    justify-content: center
    height: 6.125rem
    font-weight: 600

  &_text
    margin: 0
    width: 35%

  &_time
    display: flex
    align-items: baseline
    justify-content: space-around
    font-family: "MontserratBold", sans-serif
    font-weight: bold
    font-size: 2rem
    padding: 0 1.25rem

    &_text
      font-size: 1.15rem
      text-align: center
      width: 100%

    &_item
      display: flex
      text-align: center
      flex-direction: column
      align-items: center
      justify-content: center
      padding: 0 1.5rem

@media (max-width: 560px)
  .timer
    display: none