@import "fonts"
@import "constants"
@import "commonElements"

*
  box-sizing: border-box
  scroll-behavior: smooth

.App
  overflow-x: hidden
  background: $purple
  padding-top: 6rem


@import 'Navbar'
@import "Header"
@import "InfoInNumber"
@import "ProosvitaInfo"
@import "Capabilities"
@import "Course"
@import "Learn"
@import "Study"
@import "Security"
@import "Partners"
@import "Teacher"
@import "Footer"
@import "Form"
@import "Admin"