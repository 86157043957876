@import "./src/styles/fonts"
@import "./src/styles/constants"

.btn
  cursor: pointer
  background: #39b54a
  border: 2px solid #39b54a
  border-radius: 5px
  color: white
  font-family: 'MontserratMedium', sans-serif
  transition: 0.3s
  &:hover
    border-color: white

.button
  font-size: 1.2rem
  padding: 0.5rem 1.3rem

.buttonBig
  font-size: 1.5rem
  padding: 1rem 4rem


@media (max-width: 560px)
  .buttonBig
    font-size: 1.8rem
    padding: 1.3rem 4rem