.navbar
  position: fixed
  width: 100%
  top: 0
  z-index: 10
  padding: 2rem 0
  background: $purple

  &_listItem
    display: flex
    align-items: center
    justify-content: space-between
    padding: 0
    margin: 0
    list-style: none

  &_item
    &--button
      font-family: "MontserratMedium", sans-serif

    &--menu
      & > a
        color: white
        opacity: 0.8
        position: relative
        font-family: "MontserratMedium", sans-serif
        font-size: 1.25rem
        display: block
        border-radius: 5px
        padding: 0.5rem 0.7rem
        transition: 0.3s

        &:hover, &:focus
          opacity: 1

        &:active
          transform: scale(0.9)

    &--link
      margin: 0 1rem

  &_link
    text-decoration: none

    &--logo
      height: 2rem
      margin-right: 2rem

      & > a
        height: 100%
        width: 100%

      &_purple
        height: 3rem
        display: none

    &--facebook
      padding: 0 1rem
.burgerMenu
  display: none

@media (max-width: 960px)
  .navbar
    background-color: white
    &_item
      &--button
        display: none
      &--menu
        display: none
    &_link
      &--facebook
        display: none
      &--instagram
        display: none
      &--logo
        display: none
        &_purple
          display: block
  .burgerMenu
    display: block
    padding-left: 8rem
    text-align: right

@media (max-width: 390px)
  .burgerMenu
    padding-left: 2rem