.sponsors
  background: white
  padding: 5rem 0
  text-align: center

  &_header
    margin: auto
    font-family: "MontserratBold", sans-serif
    font-size: 2.375rem
    color: $purple
    width: 80%
    text-transform: uppercase
    position: relative
    &:before
      content: ""
      position: absolute
      background: $purple
      height: 0.375rem
      width: 10%
      bottom: -2rem
      left: 45%



  &_wrapper
    padding-top: 2rem
    display: flex
    align-items: center
    flex-direction: column

  &_list
    display: flex
    align-items: center
    flex-direction: column

    &--line
      display: flex
      position: relative
  &_logo
    width: 5rem

  &_item
    display: flex
    flex-direction: column
    align-items: center
    justify-content: space-between
    text-align: center
    padding: 2rem
    position: relative

    &--img
      display: flex
      justify-content: center
      align-items: center
      height: 100%

    &--text
      font-family: 'MontserratReg', sans-serif
      font-size: 0.875rem
      color: $purple
      padding-top: 1.125rem

@media (max-width: 940px)
  .sponsors
    &_list
      &--line
        justify-content: center
        align-items: center
        flex-wrap: wrap

@media (max-width: 540px)
  .sponsors
    &_header
      &:before
        width: 20%
        left: 40%